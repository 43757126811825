@font-face {
  font-family: 'young-serif--regular';
  src: url("../fonts/young-serif--regular/young-serif--regular.eot");
  src: url("../fonts/young-serif--regular/young-serif--regular.eot?#iefix") format('embedded-opentype'), url("../fonts/young-serif--regular/young-serif--regular.woff") format('woff'), url("../fonts/young-serif--regular/young-serif--regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --ratio: 1.13;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.195;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.26;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
html[data-focus-source="key"] :focus {
  z-index: 90;
  outline-offset: 6px;
  outline: 2px solid #ebc71b;
}
html :focus {
  outline: 0;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}
.main_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../images/background_image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.page_content {
  flex: 1;
  width: 100%;
}
.page_content__inner {
  display: flex;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
}
.page_content__inner > *:first-child {
  margin-top: 2.427rem;
}
.page_content__inner > *:last-child {
  margin-bottom: 2.427rem;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 13px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5833333333333334vw + 10.2px);
  }
}
@media screen and (min-width: 1680px) {
  :root {
    font-size: 20px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: 'young-serif--regular', sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: -0.05em;
  color: #2f333b;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: 'young-serif--regular', sans-serif;
  font-weight: normal;
  letter-spacing: -0.05em;
  color: #282b35;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: #2753a1;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: #224194;
}
h1 {
  max-width: 37ch;
  font-size: 1.630473609999999rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 2.039255400624999rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.52047376rem;
    font-size: var(--fz-ratio-power--four);
  }
}
* + h1 {
  margin-top: 2.445710414999999rem;
  margin-top: var(--sp-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  * + h1 {
    margin-top: 3.058883100937499rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  * + h1 {
    margin-top: 3.780710640000001rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
h2 {
  max-width: 37ch;
  font-size: 1.442896999999999rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.706489874999999rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 2.000376rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 2.164345499999999rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.559734812499999rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 3.000564rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 51.8ch;
  font-size: 1.2769rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.428025rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.5876rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 1.91535rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 2.142037499999999rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.3814rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 64ch;
  font-size: 1.13rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.195rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.26rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.695rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.7925rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.89rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: 'young-serif--regular', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.05em;
  color: #282b35;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content {
  width: 74ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  color: #2753a1;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus {
  color: #224194;
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
}
b,
strong {
  font-weight: normal;
  font-family: 'young-serif--regular', sans-serif;
}
i,
em {
  font-style: normal;
  font-family: 'young-serif--regular', sans-serif;
}
::selection {
  background-color: #008091;
  text-shadow: 0 0 0.1em #005e60;
  color: #fff;
}
.reading_content ol,
.reading_content ul {
  display: block;
  width: 74ch;
  max-width: 100%;
  padding-left: 3ch;
}
* + .reading_content ol,
* + .reading_content ul {
  margin-top: 1.618rem;
}
.reading_content ol:not(first-child),
.reading_content ul:not(first-child) {
  margin-top: 1.618rem;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content li {
  max-width: 100%;
}
.reading_content li + li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 0.884955752212389rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.836820083682008rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.793650793650794rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header {
  width: 100%;
  border-bottom: 2px solid #067078;
}
.global_header__inner {
  display: flex;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  padding: 3.236rem 0;
}
@media only screen and (min-width: 681px) {
  .global_header__inner {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.global_header__title_and_summary {
  flex: 1 1 auto;
}
* + .global_header__summary {
  margin-top: 0.20225rem;
}
.global_header__profile_figure {
  flex: 0 0 32%;
}
* + .global_header__profile_figure {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  * + .global_header__profile_figure {
    margin-top: 0;
  }
  * + .global_header__profile_figure {
    margin-left: 1.618rem;
  }
}
.global_header__profile_image {
  box-shadow: 4px 4px 0 #067078;
}
.cvs_and_contact {
  width: 100%;
  border-top: 2px solid #fff;
  padding-top: 3.236rem;
}
* + .cvs_and_contact {
  margin-top: 3.236rem;
}
.cvs_and_contact__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
.cvs_and_contact__item {
  display: flex;
  align-items: stretch;
  flex: 0 0 100%;
  display: flex;
  box-shadow: 0 1px 0.06rem rgba(17,21,25,0.4);
}
@media only screen and (min-width: 681px) {
  .cvs_and_contact__item {
    flex: 0 0 49%;
  }
}
@media only screen and (min-width: 1281px) {
  .cvs_and_contact__item {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 680px) {
  * + .cvs_and_contact__item {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .cvs_and_contact__item {
    min-height: 100%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .cvs_and_contact__item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1281px) {
  .cvs_and_contact__item:nth-child(3n+2),
  .cvs_and_contact__item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .cvs_and_contact__item:nth-child(n+3) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 1281px) {
  .cvs_and_contact__item:nth-child(n+4) {
    margin-top: 2%;
  }
}
.cvs_and_contact__item:last-child {
  margin-right: auto;
}
@media only screen and (min-width: 1281px) {
  .cvs_and_contact__item:last-child {
    margin-left: auto;
  }
}
.cvs_and_contact__cv_anchor {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding-bottom: 4px;
}
.cvs_and_contact__cv_anchor:active::before,
.cvs_and_contact__cv_anchor:focus::before,
.cvs_and_contact__cv_anchor:hover::before {
  width: 100%;
}
.cvs_and_contact__cv_anchor::before {
  will-change: width;
  transition-property: width;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: #008091;
}
.cvs_and_contact__cv_image {
  flex: 0 0 auto;
  min-width: 100%;
}
.cvs_and_contact__cv_title {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  flex: 1 1 auto;
  width: 100%;
  background-color: #fff;
  padding: 0.809rem;
}
* + .cvs_and_contact__cv_title {
  margin-top: 0;
}
.cvs_and_contact__contact_details_anchor {
  will-change: background-color;
  transition-property: background-color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #067078;
  padding: 1.618rem;
}
.cvs_and_contact__contact_details_anchor:active,
.cvs_and_contact__contact_details_anchor:focus,
.cvs_and_contact__contact_details_anchor:hover {
  background-color: #036c74;
}
.cvs_and_contact__contact_details_anchor:active .cvs_and_contact__contact_details_anchor_text,
.cvs_and_contact__contact_details_anchor:focus .cvs_and_contact__contact_details_anchor_text,
.cvs_and_contact__contact_details_anchor:hover .cvs_and_contact__contact_details_anchor_text {
  transform: scale(1.1);
}
.cvs_and_contact__contact_details_anchor_text {
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  text-shadow: 0 0 0.24em #005e60;
  font-size: 1.2769rem;
  font-size: var(--fz-ratio-power--two);
  text-align: center;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .cvs_and_contact__contact_details_anchor_text {
    font-size: 1.428025rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .cvs_and_contact__contact_details_anchor_text {
    font-size: 1.5876rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.focus_pulling_showreel {
  width: 100%;
}
.focus_pulling_showreel__title {
  text-shadow: 0 0 0.24em #1d1f26;
  color: #fff;
}
.focus_pulling_showreel__video_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
* + .focus_pulling_showreel__video_list {
  margin-top: 1.618rem;
}
.focus_pulling_showreel__video_item {
  flex: 0 0 100%;
  display: flex;
  box-shadow: 0 1px 0.06rem rgba(17,21,25,0.4);
  background-color: #000;
}
@media only screen and (min-width: 681px) {
  .focus_pulling_showreel__video_item {
    flex: 0 0 49%;
  }
}
@media only screen and (min-width: 1281px) {
  .focus_pulling_showreel__video_item {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 680px) {
  * + .focus_pulling_showreel__video_item {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .focus_pulling_showreel__video_item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1281px) {
  .focus_pulling_showreel__video_item:nth-child(3n+2),
  .focus_pulling_showreel__video_item:nth-child(3n+3) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) and (max-width: 1280px) {
  .focus_pulling_showreel__video_item:nth-child(n+3) {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 1281px) {
  .focus_pulling_showreel__video_item:nth-child(n+4) {
    margin-top: 2%;
  }
}
.focus_pulling_showreel__video_item:last-child {
  margin-right: auto;
}
.focus_pulling_showreel__video {
  position: relative;
  z-index: auto;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  max-width: 100%;
}
.focus_pulling_showreel__video_iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
